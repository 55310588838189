import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import LocaleSwitcher from "../common/components/LocaleSwitcher";

const Landing = () => {
  const [state, setState] = React.useState({
    checkedA: true,
  });
  const [occasions, setOccasions] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (i18n.language === "en" || i18n.language === undefined) {
      setState({ checkedA: false });
    } else {
      setState({ checkedA: true });
    }
  }, []);
  
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    company: "",
    occasion: "",
  });

    useEffect(() => {
    // Update formData.company when language changes
    const translatedCompany = t("robi-axiata-plc");
    setFormData((prevFormData) => ({
      ...prevFormData,
      company: translatedCompany,
    }));
    }, [t]);

  const [error, setError] = useState({
    occasion : ""
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    let newError = { ...error };

  if (formData.occasion === "") {
    newError = {
      ...newError,
      occasion: "Select an occasion",
    };
  } else {
    newError = {
      ...newError,
      occasion: "", // Clear the error message if occasion is not empty
    };
  }

  setError(newError);

  if (formData.occasion !== "" ) {
    navigate("/card-preview", { state: formData });
  }
    
  };

  const localeHandler = (checked) => {
    if (checked) {
      i18n.changeLanguage("bn");
    } else {
      i18n.changeLanguage("en");
    }
  };

  useEffect(() => {
    fetchOccasions();
  }, []);

 const fetchOccasions = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_OCCASOIN_API);
      if (response.ok) {
        const data = await response.json();
        if (data.status === "SUCCESS") {
          setOccasions(data.data);
        } else {
          console.error("API response status is not success:", data.error);
        }
      } else {
        console.error("Failed to fetch occasions:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching occasions:", error);
    }
  };


  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 300);
  }, []);

  return (
    <>
      
      <div className="w-full h-screen overflow-hidden relative flex justify-center items-center flex-col px-4">
        <img
          className="hidden md:block absolute top-0 left-0 w-full h-full object-cover object-top -z-10"
          src="/background.jpg"
          alt=""
        />
        <img
          className="md:hidden absolute top-0 left-0 w-full h-full object-cover object-top -z-10"
          src="/background-sm.jpg"
          alt=""
        />

        <img
          className="brand_logo absolute top-6 w-[70px] md:w-[100px] xl:w-[143px] 2xl:top-4  left-1/2 -translate-x-1/2 md:left-[90%]"
          src="/logo_robi_horizontal.svg"
          alt=""
        />

        {loader ? 
          <div className="relative w-full h-[100px]">
              <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
              </svg>
          </div>
            :
          <div className="relative w-full sm:max-w-sm mt-10 sm:mt-0 bg-white border border-gray-100 rounded-lg mx-auto z-50 box-shadow p-4">
            <div className=" w-full h-full ">
              <div className="locale-wrapper">
                <LocaleSwitcher
                  state={state}
                  setState={setState}
                  localeHandler={localeHandler}
                />
              </div>
              <form className="space-y-3" onSubmit={onSubmitHandler}>
                <h5 className="md:text-2xl text-xl font-bold text-[#BC2626]">
                  {t("title")}
                </h5>
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-[#5E6A7A]"
                  >
                    {t("name")}:
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 focus:outline-none focus:rounded-none"
                    placeholder={t("enterName")}
                    autoComplete="off"
                  />
                </div>
                <div>
                  <label
                    htmlFor="designation"
                    className="block mb-2 text-sm font-medium text-[#5E6A7A]"
                  >
                    {t("designation")}:
                  </label>
                  <input
                    type="text"
                    name="designation"
                    id="designation"
                    value={formData.designation}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5  focus:outline-none focus:rounded-none"
                    placeholder={t("enterDesignation")}
                    autoComplete="off"
                  />
                </div>
                <div>
                  <label
                    htmlFor="company"
                    className="block mb-2 text-sm font-medium text-[#5E6A7A]"
                  >
                    {t("company")}:
                  </label>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    value={formData.company || ''}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5  focus:outline-none focus:rounded-none"
                    placeholder={t("enterCompany")}
                    autoComplete="off"
                  />
                </div>
                <div>
                  <label
                    htmlFor="occasion"
                    className="block mb-2 text-sm font-medium text-[#5E6A7A]"
                  >
                    {t("occasion")}:
                    <span className="text-sm text-red-500 font-medium">*</span>
                  </label>
                  <div className="select-wrapper">
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5  focus:outline-none focus:rounded-none"
                      name="occasion"
                      id="occasion"
                      onChange={handleChange}
                    >
                      <option value="">{t("select-an-occasion")}</option>
                      {occasions.map((occasion) => (
                        <option key={occasion.id} value={occasion.id}>
                          {i18n.language === "en" ? occasion.title_en : occasion.title_bn}
                        </option>
                      ))}
                      <span>arrow</span>
                    </select>
                  </div>
                  {error.occasion && <p className="text-sm text-red-500 font-medium">{error.occasion}</p>}
                </div>

                <button
                  type="submit"
                  className="w-full text-white bg-[#5B2D90] hover:bg-purple-900 focus:ring-4  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onSubmit={onSubmitHandler}
                >
                  {t("generateCard")}
                </button>
              </form>
            </div>
          </div>
        }

        
      </div>
    </>
  );
};

export default Landing;
